import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminAuthResponse } from '../../../types/AdminAuthResponse';
import { Router } from '@angular/router';
import { BaseService } from '../../service/base.service';
import { APIResponse } from '../../../types/APIResponse';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {

  constructor(http: HttpClient, private router: Router) { 
    super(http);
  }

  signUpUser(userData: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.post(`sign_up/user`, userData, headers );
  }

  signInAdmin(loginData: any): Observable<AdminAuthResponse> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.post(`sign_in/admin`, loginData, headers );
  }

  forgotPassword(email: string): Observable<APIResponse<null>> {
    return this.http.post<APIResponse<null>>(`https://lawyers-dev-api.thelawyerss.com/api/account/forgot_password`, {
      email: email
    })
  }
  updatePassword(payload: any): Observable<APIResponse<null>> {
    return this.http.post<APIResponse<null>>(`https://lawyers-dev-api.thelawyerss.com/api/account/reset_password`, payload)
  }
  

  // Check where is Authenticated
  isAuthenticated(): boolean {
    const token = localStorage.getItem('accessToken');
    //check token validity and other stuff
    return !!token;
  }

  // Logout admin user
  logoutAdmin() {
    localStorage.clear()
    this.router.navigate(['/'])
  }
}
